<template>
    <div class="buddy">
        <div class="container">
            <div class="title2 title-border">{{ $t('serviceBuddy.buddy') }}</div>
        </div>
        <div class="container-fuild">
            <div class="blockquote">
                <blockquote class="quote-bq">
                    {{ $t('serviceBuddy.bq') }}
                </blockquote>
            </div>
        </div>
        <div class="detail-bq">
            <div class="container">
                <p>{{ $t('serviceBuddy.detail1') }}</p><br><br>
                <p>{{ $t('serviceBuddy.detail2') }}</p><br><br>
                <p>{{ $t('serviceBuddy.detail3') }}</p><br><br>
                <p>{{ $t('serviceBuddy.detail4') }}</p><br><br>
                <p>{{ $t('serviceBuddy.detail5') }}</p><br><br>
                <div class="row row-buddy" v-for="(item, index) in items" :key="index">
                    <div class="column"><img v-lazy="{src: item.img1}" alt="" onclick="openModal();currentSlide(1)" class="hover-shadow cursor"></div>
                    <div class="column"><img v-lazy="{src: item.img2}" alt="" onclick="openModal();currentSlide(2)" class="hover-shadow cursor"></div>
                    <div class="column"><img v-lazy="{src: item.img3}" alt="" onclick="openModal();currentSlide(3)" class="hover-shadow cursor"></div>
                    <div class="column"><img v-lazy="{src: item.img4}" alt="" onclick="openModal();currentSlide(4)" class="hover-shadow cursor"></div>
                    <div class="column"><img v-lazy="{src: item.img5}" alt="" onclick="openModal();currentSlide(5)" class="hover-shadow cursor"></div>
                    <div class="column"><img v-lazy="{src: item.img6}" alt="" onclick="openModal();currentSlide(6)" class="hover-shadow cursor"></div>
                    <div class="column"><img v-lazy="{src: item.img7}" alt="" onclick="openModal();currentSlide(7)" class="hover-shadow cursor"></div>
                    <div class="column"><img v-lazy="{src: item.img8}" alt="" onclick="openModal();currentSlide(8)" class="hover-shadow cursor"></div>

                    <div id="myModal" class="modal">
                        <span class="close cursor" onclick="closeModal()">&times;</span>
                        <div class="modal-content">
                            <div class="mySlides">
                                <div class="numbertext">1 / 8</div>
                                <img v-lazy="{src: item.img1}" alt="" style="width:100%">
                            </div>
                            <div class="mySlides">
                                <div class="numbertext">2 / 8</div>
                                <img v-lazy="{src: item.img2}" alt="" style="width:100%">
                            </div>
                            <div class="mySlides">
                                <div class="numbertext">3 / 8</div>
                                <img v-lazy="{src: item.img3}" alt="" style="width:100%">
                            </div>
                            <div class="mySlides">
                                <div class="numbertext">4 / 8</div>
                                <img v-lazy="{src: item.img4}" alt="" style="width:100%">
                            </div>
                            <div class="mySlides">
                                <div class="numbertext">5 / 8</div>
                                <img v-lazy="{src: item.img5}" alt="" style="width:100%">
                            </div>
                            <div class="mySlides">
                                <div class="numbertext">6 / 8</div>
                                <img v-lazy="{src: item.img6}" alt="" style="width:100%">
                            </div>
                            <div class="mySlides">
                                <div class="numbertext">7 / 8</div>
                                <img v-lazy="{src: item.img7}" alt="" style="width:100%">
                            </div>
                            <div class="mySlides">
                                <div class="numbertext">8 / 8</div>
                                <img v-lazy="{src: item.img8}" alt="" style="width:100%">
                            </div>
                            <a class="prev" onclick="plusSlides(-1)">&#10094;</a>
                            <a class="next" onclick="plusSlides(1)">&#10095;</a>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <p class="detail-good">{{ $t('serviceBuddy.titleGood') }}</p>
                        <div class="good-content">
                            <p>{{ $t('serviceBuddy.good1') }}</p>
                            <p>{{ $t('serviceBuddy.good2') }}</p>
                            <p>{{ $t('serviceBuddy.good3') }}</p>
                            <p>{{ $t('serviceBuddy.good4') }}</p>
                            <p>{{ $t('serviceBuddy.good5') }}</p>
                            <p>{{ $t('serviceBuddy.good6') }}</p>
                            <p>{{ $t('serviceBuddy.good7') }}</p>
                            <p>{{ $t('serviceBuddy.good8') }}</p>
                            <p>{{ $t('serviceBuddy.good9') }}</p>
                            <p>{{ $t('serviceBuddy.good10') }}</p>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <p class="detail-good">{{ $t('serviceBuddy.price') }}</p>
                        <div class="good-content">
                            <p>{{ $t('serviceBuddy.price1') }}</p>
                            <p>{{ $t('serviceBuddy.price2') }}</p>
                            <p>{{ $t('serviceBuddy.detailPrice') }}</p>
                        </div>
                    </div>
                </div>
                <p>{{ $t('serviceBuddy.detailEnd') }}</p>
                <p>{{ $t('serviceBuddy.detailEnd2') }}</p><br>
                <p>📞 083-424-9090</p>
                <p>Line ID : silpaphat</p>
                <p>✉ : info@assetup.co.th</p>
                <p>🌏 : https://www.assetup.co.th/#/</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            items: [
                { 
                    img1: require('../assets/buddy/S__2170888.webp'),
                    img2: require('../assets/buddy/S__2170890.webp'),
                    img3: require('../assets/buddy/Screen Shot 2021-06-15 at 1.51.22 PM.webp'),
                    img4: require('../assets/buddy/Screen Shot 2021-06-15 at 1.51.37 PM.webp'),
                    img5: require('../assets/buddy/Screen Shot 2021-06-15 at 1.51.47 PM.webp'),
                    img6: require('../assets/buddy/Screen Shot 2021-06-15 at 1.51.57 PM.webp'),
                    img7: require('../assets/buddy/9C95BD65-6133-467C-9968-2229C74995A8.webp'),
                    img8: require('../assets/buddy/ACC94FB8-4B14-49E1-94B1-33C9B7E60528.webp')
                },
            ]
        }
    }
}
</script>